import React from "react";
import { graphql } from "gatsby";
import AboutContent from "../components/aboutContent";
import i18nMessages from '../i18n/sv.json';
import 'intl/locale-data/jsonp/sv';
import PageWrapper from "../components/pageWrapper";

const About = (props) => {
    return (
       <PageWrapper location={props.location} lang="sv" i18nMessages={i18nMessages}>
            <AboutContent location={props.location} langUrl="/sv" />
        </PageWrapper>
    )
    
}

export default About

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`